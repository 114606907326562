import type {
	PaymentMethodCategories,
	PaymentMethodCode,
} from '../../core/types';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type CategoriesDTO = Response<
	{
		id: string;
		name: string;
		code: 'bankovskie_karty' | 'elektronnye_koshelki' | 'kriptovalyuta';
		items: {
			id: string;
			name: string;
			freekassa_id: string;
			image: string;
		}[];
	}[]
>;

const codeMap: Record<
	CategoriesDTO['result'][number]['code'],
	PaymentMethodCode
> = {
	bankovskie_karty: 'bankCard',
	elektronnye_koshelki: 'electroWallet',
	kriptovalyuta: 'crypto',
};

export const toDomain = (data: CategoriesDTO): PaymentMethodCategories =>
	data.result.map(category => ({
		id: category.id,
		code: codeMap[category.code],
		name: category.name,
		items: category.items.map(item => ({
			id: item.id,
			code: item.freekassa_id,
			name: item.name,
			image: item.image,
		})),
	}));

export const useGetCategories = () => {
	const api = useApi();

	return async () =>
		(await api<CategoriesDTO>('/Paysystems/get/')).mapRight(toDomain);
};
