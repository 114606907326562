<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';
import { closeProfileModal } from '../core/application';
import { useIsProfileModalOpen } from '../infrastructure/store';
import EditInfo from './Edit/Info.vue';
import EditLogin from './Edit/Login.vue';
import EditPassword from './Edit/Password.vue';
import EditSettings from './Edit/Settings.vue';
import EditLocale from './Edit/Locale.vue';
import EditMailing from './Edit/Mailing.vue';
import EditEmail from './Edit/Email.vue';
import EditTraffic from './Edit/Traffic.vue';
import EditPersonal from './Edit/Personal.vue';
import EditPayment from './Edit/Payment.vue';
import EditVerificationCenter from './Edit/VerificationCenter';
import { useIsUserPartner, useIsUserTrader } from '@/src/features/user';

const isOpen = useIsProfileModalOpen();
const close = closeProfileModal;

const isTrader = useIsUserTrader();
const isPartner = useIsUserPartner();
</script>

<template>
	<div
		v-if="isOpen"
		class="fixed top-0 md:top-[64px] left-0 right-0 bottom-0 bg-gray-800 flex w-full flex-col gap-8 pb-10 pt-4 z-[60] md:z-[50] overflow-y-auto px-4"
	>
		<div class="grid grid-cols-[1fr_auto_1fr] items-center">
			<span />

			<h1 class="text-center text-base font-semibold text-white">
				Профиль
			</h1>

			<div class="flex justify-end">
				<button
					class="rounded-lg p-1 text-gray-400 hover:bg-gray-700 hover:text-white"
					type="button"
					@click="close"
				>
					<BaseIcon
						name="base--fill--x"
						class="text-[18px]"
					/>
				</button>
			</div>
		</div>

		<div class="mx-auto flex w-full max-w-[736px] flex-col gap-4">
			<EditInfo />

			<EditLogin />

			<EditVerificationCenter v-if="isTrader" />

			<EditEmail v-if="isPartner" />

			<EditPersonal v-if="isPartner" />

			<EditPayment v-if="isPartner" />

			<EditTraffic v-if="isPartner" />

			<EditPassword />

			<EditSettings />

			<EditLocale />

			<EditMailing v-if="isPartner" />
		</div>
	</div>
</template>
