<script setup lang="ts">
import { pageDrawers } from '../core';
import type { PageDrawers } from '../core/types';
import { useOpenTerminalPage } from '~/src/shared/lib';
import { DrawersItem } from '~/src/modules/drawers';

defineProps<{
	name: PageDrawers;
	title: string;
	fillscreen?: boolean;
}>();

const openTerminalPage = useOpenTerminalPage();
const onClose = () => {
	pageDrawers.close();
	openTerminalPage();
	// TODO: Remove this when we have a better way to handle this
};
</script>

<template>
	<DrawersItem
		:name="name"
		:title="title"
		:fullscreen="fillscreen"
		:use-drawer-names="pageDrawers.useDrawerNames"
		@back="pageDrawers.back()"
		@close="onClose"
	>
		<slot />
	</DrawersItem>
</template>
