import { useApi } from '@/src/services/network';

export const useUploadAvatar = () => {
	const api = useApi();

	return async (avatar: File) => {
		const formData = new FormData();
		formData.append('personal_photo', avatar as Blob);

		return await await api('/User/update/', {
			method: 'post',
			body: formData,
		});
	};
};
