<script setup lang="ts">
import { formatAmount, BaseIcon } from '@legacy-studio/library';
import type { Deal } from '../core/types';
import { formatDate } from '~/src/shared/lib';

const props = defineProps<{
	data: Deal;
}>();

const fromTime = formatDate(props.data.startDate, 'HH:mm:ss');
const toTime = formatDate(props.data.expirationDate, 'HH:mm');
</script>

<template>
	<div
		class="py-3 grid grid-cols-[1fr_1fr_2fr] gap-[14px] border-b border-gray-600"
	>
		<div class="flex flex-col gap-0.5">
			<div class="text-xs font-medium text-white">
				{{ fromTime }}
			</div>

			<div class="text-xs font-medium text-gray-500">
				{{ toTime }}
			</div>
		</div>

		<div class="text-xs font-medium text-white flex flex-col">
			<span>ID</span>
			<span>{{ data.id }}</span>
		</div>

		<div class="flex gap-[14px]">
			<div class="flex flex-col">
				<div class="flex gap-2 items-center">
					<BaseIcon
						v-if="data.type === 'down'"
						name="base--fill--arrow-down"
						class="text-[16px] text-red-400 rotate-[-45deg]"
					/>
					<BaseIcon
						v-else
						name="base--fill--arrow-up"
						class="text-[16px] text-green-400 rotate-45"
					/>

					<span class="text-xs font-medium text-white">{{
						data.startSum
					}}</span>
				</div>

				<div class="flex gap-2 items-center">
					<BaseIcon
						name="base--fill--flag"
						class="text-[16px] text-gray-500"
					/>

					<span class="text-xs font-medium text-white">{{
						data.resultSum
					}}</span>
				</div>
			</div>

			<div
				class="flex justify-end flex-col text-xs font-medium ml-auto w-full text-right"
			>
				<span class="text-gray-50">
					{{ formatAmount(data.sum) }}
				</span>

				<span
					:class="{
						'text-red-400': !data.income,
						'text-green-400': data.income,
					}"
				>
					{{ formatAmount(data.income) }}
				</span>
			</div>
		</div>
	</div>
</template>
