<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseButton,
	BaseCard,
	BaseCardTitle,
	BaseTextField,
	getTextFieldBinds,
	useAsyncState,
} from '@legacy-studio/library';
import { changePasswordSchema } from '../../core/validation';
import {
	useChangePassword,
	openChangePasswordModal,
	closeChangePasswordModal,
} from '../../core/application';
import { useIsChangePasswordEdit } from '../../infrastructure/store';
import type { ChangePasswordForm } from '../../types';
import EditForm from './Form.vue';
import { UiVerificationLabel } from '@/src/shared/ui';

const isChangePasswordEdit = useIsChangePasswordEdit();

const { defineField, handleSubmit, resetForm } = useForm<ChangePasswordForm>({
	validationSchema: changePasswordSchema,
});
const [password, passwordProps] = defineField('password', getTextFieldBinds);
const [newPassword, newPasswordProps] = defineField(
	'newPassword',
	getTextFieldBinds,
);
const [newPasswordConfirmation, newPasswordConfirmationProps] = defineField(
	'newPasswordConfirmation',
	getTextFieldBinds,
);

const { execute, isLoading } = useAsyncState(useChangePassword());

const onSubmit = handleSubmit(async (values) => {
	await execute(values);
	resetForm(); // TODO: hook
});
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Пароль установлен" />

		<div class="flex items-center justify-between gap-[30px] pb-4">
			<UiVerificationLabel
				status="verified"
				title="*************"
				subtitle="Если вы заметили подозрительную активность, смените пароль."
			/>

			<BaseButton
				v-if="!isChangePasswordEdit"
				color="alternative-dark"
				size="xs"
				button-text="Изменить"
				outline
				class="w-full md:w-auto"
				@click="openChangePasswordModal"
			/>
		</div>

		<EditForm
			v-if="isChangePasswordEdit"
			class="mt-4"
			@submit="onSubmit"
			@cancel="closeChangePasswordModal"
		>
			<BaseTextField
				v-model="password"
				v-bind="passwordProps"
				size="regular"
				dark
				type="password"
				placeholder="Введите старый пароль"
				label="Старый пароль"
			/>

			<BaseTextField
				v-model="newPassword"
				v-bind="newPasswordProps"
				size="regular"
				dark
				type="password"
				placeholder="Введите новый пароль"
				label="Новый пароль"
			/>

			<BaseTextField
				v-model="newPasswordConfirmation"
				v-bind="newPasswordConfirmationProps"
				size="regular"
				dark
				type="password"
				placeholder="Повторите новый пароль"
				label="Повторите пароль"
			/>
		</EditForm>
	</BaseCard>
</template>
