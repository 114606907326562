<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseToggle,
	useAsyncState,
} from '@legacy-studio/library';

const isMailing = computed(() => false);

const changeMailing = async () => {
	// TODO
};

const { execute, isLoading } = useAsyncState(changeMailing);
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Управление рассылками" />

		<div class="flex gap-8 justify-between">
			<div class="flex flex-col gap-1">
				<p class="text-lg font-semibold text-white">
					Я хочу получать рассылку
				</p>

				<p class="text-base font-normal text-gray-400">
					Вы можете настраивать рассылку, чтобы всегда быть в курсе событий
				</p>
			</div>

			<BaseToggle
				:model-value="isMailing"
				@update:model-value="execute"
			/>
		</div>
	</BaseCard>
</template>
