import type { SetParticipateTournament } from '~/src/features/tournaments/core/ports.input';
import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';
import { saveInvoices } from '~/src/features/invoices/infrastructure/store';
import { saveTournamentAvailable } from '~/src/features/tournaments/infrastructure/store';

export const setParticipateTournament: SetParticipateTournament = async (
	tournamentId,
) => {
	const result = await tournamentsApi.setParticipateInTournament(tournamentId);
	if (result.isRight()) {
		saveInvoices(result.value);
		saveTournamentAvailable(tournamentId);
	}
};
