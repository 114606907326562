import { apiMethods } from '../../infrastructure/api';
import { useGetUser } from '~/src/features/user';

export const useChangeLocale = () => {
	const changeLocale = apiMethods.useChangeLocale();
	const getUser = useGetUser();

	return async (language: string) => {
		const result = await changeLocale(language);

		if (result.isRight()) {
			await getUser();
		}
		else {
			throw result.value;
		}
	};
};
