import { saveBonusesSelected } from '../../infrastructure/store';
import type { BonusesItem } from '../../types';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

export const useSelectBonusesItem = () => {
	return (item: BonusesItem) => {
		saveBonusesSelected(item);
		pageDrawers.open(PAGE_DRAWERS.bonusesDetail, { nested: true });
	};
};
