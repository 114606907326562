import { apiMethods } from '../../infrastructure/api';
import { saveIsLoginEdit } from '../../infrastructure/store';
import type { EditLoginForm } from '../../types';
import { useGetUser } from '@/src/features/user';

export const useUpdateLogin = () => {
	const updateLogin = apiMethods.useUpdateLogin();
	const getUser = useGetUser();

	return async (form: EditLoginForm) => {
		const result = await updateLogin(form);

		if (result.isRight()) {
			await getUser();
			setLoginNotEdit();
		}
		else {
			throw result.value;
		}
	};
};

export const setLoginEdit = () => {
	saveIsLoginEdit(true);
};

export const setLoginNotEdit = () => {
	saveIsLoginEdit(false);
};
