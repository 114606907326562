<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseTextField,
	getTextFieldBinds,
	useAsyncState,
	type BaseIconName,
	BaseFetchWrapper,
	BaseCheckbox,
} from '@legacy-studio/library';
import { useCheckPromocode, clearPromocodeInfo } from '../core/application';
import { usePromocodeInfo } from '../infrastructure/store';
import { promocodeSchema } from '../core/validation';
import type { PromocodeForm } from '../core/types';
import { UiDarkBBButton } from '~/src/shared/ui';

const promocodeInfo = usePromocodeInfo();

const hasPromo = ref(!!promocodeInfo.value?.value);

const errorMessage = '';

const iconStyle = computed<BaseIconName | undefined>(() => {
	if (!promocodeInfo.value) {
		return undefined;
	}
	else if (errorMessage) {
		return 'base--fill--x-circle';
	}
	else {
		return 'base--fill--check-circle';
	}
});

const { defineComponentBinds, handleSubmit, values, resetForm }
  = useForm<PromocodeForm>({
  	validationSchema: promocodeSchema,
  	initialValues: {
  		promocode: promocodeInfo.value?.value || '',
  	},
  });

const promocode = defineComponentBinds('promocode', getTextFieldBinds);

const { execute, error, isLoading } = useAsyncState(useCheckPromocode());

const onSubmit = handleSubmit(async (values) => {
	await execute(values.promocode);
});

watch(
	() => values.promocode,
	() => {
		clearPromocodeInfo();
	},
);

watch(
	() => hasPromo.value,
	() => {
		clearPromocodeInfo();
		resetForm();
	},
);
</script>

<template>
	<BaseFetchWrapper
		:pending="isLoading"
		:error="error"
	>
		<form
			class="flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<BaseCheckbox
				v-model="hasPromo"
				label="У меня есть промокод"
			/>

			<div
				v-if="hasPromo"
				class="flex gap-4"
			>
				<BaseTextField
					v-bind="promocode"
					placeholder="Введите промокод"
					:error-message="errorMessage"
					:right-icon-style="iconStyle"
					size="regular"
					class="w-full"
				/>

				<UiDarkBBButton
					icon-only
					icon-style="base--fill--arrow-narrow-right"
					class="!rounded-lg flex-none"
					type="submit"
				/>
			</div>
		</form>
	</BaseFetchWrapper>
</template>
