import type { EditLoginForm } from '../../types';
import { useApi } from '~/src/services/network';

export const useUpdateLogin = () => {
	const api = useApi();

	return async (payload: EditLoginForm) =>
		await await api('/User/update/', {
			method: 'post',
			body: payload,
		});
};
