import { apiMethods } from '../../infrastructure/api';
import { useGetUser } from '@/src/features/user';

export const useDeleteAvatar = () => {
	const deleteAvatar = apiMethods.useDeleteAvatar();
	const getUser = useGetUser();

	return async () => {
		const result = await deleteAvatar();

		if (result.isRight()) {
			await getUser();
		}
		else {
			throw result.value;
		}
	};
};
