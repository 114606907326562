<script setup lang="ts">
import {
	BaseTextField,
	BaseIcon,
	BaseButton,
	BaseTooltip,
} from '@legacy-studio/library';
import { useAssets } from '../infrastructure/store';
import {
	getAssetsGroups,
	filterAssetsBySearch,
	filterAssetsByGroup,
} from '../utils';
import { bus } from '../infrastructure/bus';
import { useGetCurrentUserAssetPercent } from '../core/application';
import { pageDrawers } from '../../page-drawers';
import type { AssetItem } from '../types';
import AssetsListItem from './Item.vue';
import { useIsUserVip } from '@/src/features/user';
import { UiDarkBBButton } from '~/src/shared/ui';

const isVip = useIsUserVip();
const assets = useAssets();

const search = ref('');
const activeType = ref('');

const typeButtons = computed(() => getAssetsGroups(assets.value));
const filteredAssets = computed(() =>
	filterAssetsBySearch(
		filterAssetsByGroup(assets.value, activeType.value),
		search.value,
	),
);

const triggerSelectAsset = bus.useTriggerSelectAsset();
const getCurrentUserAssetPercent = useGetCurrentUserAssetPercent();

const onSelectAsset = (asset: AssetItem) => {
	triggerSelectAsset(asset);
	pageDrawers.close();
};
</script>

<template>
	<div>
		<BaseTextField
			v-model="search"
			placeholder="Поиск по активам"
			left-icon-style="base--fill--search"
			size="regular"
		/>

		<div class="flex gap-4 overflow-auto whitespace-nowrap py-6 px-4 -mx-4">
			<template
				v-for="typeButton in typeButtons"
				:key="typeButton"
			>
				<BaseButton
					v-if="typeButton !== activeType"
					color="alternative-dark"
					size="sm"
					outline
					:button-text="typeButton"
					class="!rounded-full"
					@click="activeType = typeButton"
				/>

				<UiDarkBBButton
					v-else
					color="alternative-dark"
					size="sm"
					:button-text="typeButton"
					class="!rounded-full"
					@click="activeType = typeButton"
				/>
			</template>
		</div>

		<div class="flex flex-col">
			<div
				class="flex items-center gap-[14px] text-xs font-normal justify-between text-gray-500 border-b border-gray-600 pb-3"
			>
				<span>Активы</span>

				<div class="flex gap-2 items-center">
					<span>Доходность</span>

					<span
						v-if="!isVip"
						class="flex gap-2 items-center justify-end w-[68px]"
					>
						<span>VIP</span>

						<BaseTooltip
							title=""
							direction="right"
						>
							<template #default>
								<BaseIcon
									name="base--fill--question-mark-circle"
									class="text-[16px] text-gray-500"
								/>
							</template>

							<template #description>
								Повышенная доходность по активам зависит от типа вашего
								<br>
								тарифа. Поменять тариф можно в разделе “Тарифы”
							</template>
						</BaseTooltip>
					</span>
				</div>
			</div>

			<div class="flex flex-col">
				<div
					v-for="item in filteredAssets"
					:key="item.code"
					class="flex items-center gap-[14px] justify-between py-3 border-b border-gray-600"
				>
					<div class="flex items-center gap-2">
						<AssetsListItem :asset="item" />
					</div>

					<div class="flex items-center gap-8">
						<button
							class="rounded-lg ring-1 ring-green-400 hover:bg-green-900 px-1 py-0.5 text-xs font-medium text-green-400"
							type="button"
							@click="onSelectAsset(item)"
						>
							{{ `${getCurrentUserAssetPercent(item)} %` }}
						</button>

						<div
							v-if="!isVip"
							class="rounded-lg ring-1 ring-gray-400 px-1 py-0.5 text-xs font-medium text-gray-500 flex gap-0.5 items-center"
						>
							<span>
								{{ `${item.percents.vip} %` }}
							</span>

							<BaseIcon
								name="base--fill--lock-closed"
								class="text-[16px] text-gray-500"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
