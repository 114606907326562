<script setup lang="ts">
import { useOpenedDealsSection } from '../infrastructure/store';
import SectionListItem from './SectionListItem.vue';

const openedDealsSection = useOpenedDealsSection();
</script>

<template>
	<div
		v-if="openedDealsSection?.items"
		class="flex flex-col"
	>
		<SectionListItem
			v-for="item in openedDealsSection.items"
			:key="item.startDate"
			:data="item"
		/>
	</div>
</template>
