<script setup lang="ts">
import { formatAmount, useAsyncState } from '@legacy-studio/library';
import {
	useSelectedTournament,
	useSelectedTournamentRating,
} from '../infrastructure/store';
import ActiveButton from '~/src/features/tournaments/ui/ActiveButton.vue';
import {
	getTournamentRating,
	setParticipateTournament,
	setUpdateBalance,
} from '~/src/features/tournaments';

import { getBgColor } from '@/src/features/user';
import { useInvoices } from '~/src/features/invoices/infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';
import { formatDate } from '~/src/shared/lib';

const tournamentInfo = computed(() => useSelectedTournament());

const categories = [
	{
		name: 'Условия',
		code: 'conditions',
	},
	{
		name: 'Рейтинг турнира',
		code: 'rating',
	},
];

const conditions = computed(() => {
	return tournamentInfo.value
		? [
				{
					name: 'Стартовый баланс',
					value: tournamentInfo.value.start_balance,
					type: 'currency',
				},
				{
					name: 'Минимальная сделка',
					value: tournamentInfo.value.min_deal_sum,
					type: 'currency',
				},
				{
					name: 'Максимальная сделка',
					value: tournamentInfo.value.max_deal_sum,
					type: 'currency',
				},
				{
					name: 'Лимит открытых сделок',
					value: tournamentInfo.value.deals_limit,
					type: 'currency',
				},
				{
					name: 'Лимит инвестиций',
					value: tournamentInfo.value.investments_limit,
					type: 'number',
				},
			].filter(item => item.value !== null)
		: [];
});

const filterCategory = ref('conditions');

const { execute, error, isLoading } = useAsyncState(getTournamentRating);

const getTournamentRatingUsers = (id, categoryId) => {
	filterCategory.value = categoryId;
	execute(id);
};

const ratingUsers = computed(() => useSelectedTournamentRating());

const invoices = computed(() => useInvoices());
const balanceTournamentInvoice = computed(
	() =>
		invoices.value.value.find(
			item => item?.tournament?.id === tournamentInfo.value.id,
		).balance,
);

const updateTournametBalance = (tournamentid) => {
	setUpdateBalance(tournamentid);
};

const setParticipateInTournament = () => {
	setParticipateTournament(tournamentInfo.value.id);
};
</script>

<template>
	<BaseFetchWrapper>
		<div class="flex flex-col gap-6">
			<p class="text-start text-sm font-normal text-gray-500">
				{{ tournamentInfo.description }}
			</p>

			<div>
				<div
					class="grid grid-cols-2 gap-3 py-3 border-b border-gray-600 cursor-pointer"
				>
					<div class="flex flex-col">
						<span class="text-xs pb-3 font-normal text-gray-500"> Начало </span>

						<span class="text-xs font-medium text-white">
							{{ formatDate(tournamentInfo.date_start, `DD.MM.YYYY HH:mm`) }}
						</span>
						<span class="text-xs font-medium text-gray-500"> UTC+5 </span>
					</div>

					<div class="flex flex-col">
						<span class="text-xs pb-3 font-normal text-gray-500"> Конец </span>

						<span class="text-xs font-medium text-white">
							{{ formatDate(tournamentInfo.date_end, `DD.MM.YYYY HH:mm`) }}
						</span>
						<span class="text-xs font-medium text-gray-500"> UTC+5 </span>
					</div>
				</div>

				<div
					class="grid grid-cols-2 gap-3 py-3 border-b border-gray-600 cursor-pointer"
				>
					<div class="flex flex-col">
						<span class="text-xs pb-3 font-normal text-gray-500">
							Призовой фонд
						</span>

						<span class="text-xs font-medium text-white">
							{{ formatAmount(tournamentInfo.prize_fund) }}
						</span>
					</div>
				</div>

				<div
					class="grid grid-cols-2 py-3 gap-3 border-b border-gray-600 cursor-pointer"
				>
					<div class="flex flex-col">
						<span class="text-xs pb-3 font-normal text-gray-500">
							Призовые места
						</span>

						<span class="text-xs font-medium text-white">
							{{ tournamentInfo.prize_count }}
						</span>
					</div>
				</div>
			</div>

			<ActiveButton
				:item="tournamentInfo"
				@participate="setParticipateInTournament"
			/>
			<UiDarkBBButton
				v-if="
					tournamentInfo.is_available === null
						&& balanceTournamentInvoice < tournamentInfo.start_balance
				"
				size="sm"
				:button-text="
					'Обновить балланс за '
						+ formatAmount(tournamentInfo.start_balance_update_sum)
				"
				class="!rounded-full w-100"
				@click="updateTournametBalance(tournamentInfo.id)"
			/>

			<div class="flex flex-col">
				<div
					class="flex gap-4 overflow-auto whitespace-nowrap py-4 mb-4 px-4 -mx-4"
				>
					<UiDarkBBButton
						v-for="button in categories"
						:key="button.code"
						size="sm"
						:outline="filterCategory !== button.code"
						:button-text="button.name"
						class="!rounded-full"
						@click="getTournamentRatingUsers(tournamentInfo.id, button.code)"
					/>
				</div>

				<div
					v-if="filterCategory === 'conditions'"
					class="flex flex-col"
				>
					<div
						v-for="(item, index) in conditions"
						:key="index"
						class="grid grid-cols-2 py-3 gap-3 border-b border-gray-600 cursor-pointer"
					>
						<span class="text-xs font-normal text-gray-500">
							{{ item.name }}
						</span>
						<span class="justify-self-end text-xs font-medium text-white">
							{{
								item.type === "currency" ? formatAmount(item.value) : item.value
							}}
						</span>
					</div>
				</div>

				<div
					v-if="filterCategory === 'rating'"
					class="flex flex-col"
				>
					<BaseFetchWrapper
						:pending="isLoading"
						:error="error"
					>
						<div
							class="grid grid-cols-4 py-3 gap-3 border-b border-gray-600 cursor-pointer"
						>
							<span class="col-span-2 text-xs font-medium text-gray-500">
								Участники
							</span>
							<span class="justify-self-end text-xs font-medium text-gray-500">
								Баланс $
							</span>
							<span
								class="justify-self-end text-xs px-2 font-medium text-gray-500"
							>
								Приз $
							</span>
						</div>

						<div
							v-for="item in ratingUsers"
							:key="item.login"
							class="grid grid-cols-4 py-3 gap-3 items-center border-b border-gray-600 cursor-pointer"
							:class="{ 'bg-gray-700': item.current === 1 }"
						>
							<div
								class="col-span-2 flex items-center text-xs font-medium text-white"
							>
								<span class="text-gray-500 px-2">{{ item.place }}</span>
								<img
									v-if="item.photo"
									:key="item.photo"
									:src="item.photo"
									alt="avatar"
									class="h-8 w-8 rounded-lg"
								>
								<div
									v-else
									:class="getBgColor(item)"
									class="h-8 w-8 rounded-lg flex items-center justify-center text-white text-sm font-normal"
								>
									{{ item.login[0] }}
								</div>
								<span class="text-white px-2">{{ item.login }}</span>
							</div>
							<span class="justify-self-end text-xs font-medium text-white">
								{{ item.balance }}
							</span>
							<span
								class="justify-self-end text-xs px-2 font-medium text-green-400"
							>
								{{ item.prize }}
							</span>
						</div>
					</BaseFetchWrapper>
				</div>
			</div>
		</div>
	</BaseFetchWrapper>
</template>
