<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseModal,
	BaseToggle,
	useAsyncState,
} from '@legacy-studio/library';
import { openBlockAccountModal, useBlockAccount } from '../../core/application';
import { useIsBlockAccountModalOpen } from '../../infrastructure/store';

const isBlockAccountModalOpen = useIsBlockAccountModalOpen();

const onBlockProfile = () => {
	openBlockAccountModal();
};

const { isLoading, execute } = useAsyncState(useBlockAccount());

const primaryBtn = {
	text: 'Подтвердить',
	onClick: execute,
}; // TODO
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Настройки аккаунта" />

		<div class="flex gap-8 justify-between">
			<div class="flex flex-col gap-1">
				<p class="text-lg font-semibold text-white">
					Временное закрытие аккаунта
				</p>

				<p class="text-base font-normal text-gray-400">
					Вы можете закрыть аккаунт временно. После закрытия вы не сможете войти
					в личный кабинет и совершать транзакции. Заново открыть аккаунт можно,
					обратившись в службу поддержки.
				</p>
			</div>

			<BaseToggle
				:model-value="isBlockAccountModalOpen ? true : false"
				@update:model-value="onBlockProfile"
			/>

			<BaseModal
				v-model="isBlockAccountModalOpen"
				:primary-btn="primaryBtn"
				secondary-btn
				title="Вы действительно хотите заблокировать аккаунт?"
				description=" После закрытия вы не сможете войти в личный кабинет и совершать транзакции. Заново открыть аккаунт можно, обратившись в службу поддержки."
			/>
		</div>
	</BaseCard>
</template>
