import { withdrawalApi } from '../../infrastructure/api';
import {
	readWithdrawalMethod,
	saveWithdrawalResult,
} from '../../infrastructure/store';
import type { SubmitWithdrawal } from '../ports.input';

export const submitWithdrawal: SubmitWithdrawal = async (data) => {
	const withdrawalMethod = readWithdrawalMethod();

	if (!withdrawalMethod) {
		return;
	}

	const result = await withdrawalApi.sendWithdrawal({
		withdrawalMethod,
		...data,
	});

	if (result.isRight()) {
		saveWithdrawalResult({
			type: 'success',
		});
	}
	else {
		saveWithdrawalResult({
			type: 'error',
			message: result.value.message,
		});
	}
};
