import { savePaymentMethod } from '../../infrastructure/store';
import { useSubscribePaymentMethodSelected } from '@/src/features/payment-method';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

export const useOnPaymentMethodSelect = () => {
	useSubscribePaymentMethodSelected((paymentMethod) => {
		pageDrawers.close(PAGE_DRAWERS.paymentMethod);
		savePaymentMethod(paymentMethod);
	});
};
