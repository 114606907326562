import type { TournamentRating } from '../../core/types';
import type { FetchTournamentRating } from '../../core/ports.output';
import { useApi } from '@/src/services/network';
import type { Response } from '~/src/shared/lib';

export type TournamentRatingDTO = Response<{
	users: {
		place: number;
		login: string;
		photo: string;
		balance: number;
		prize: string;
		current: 0;
		color: number;
	}[];
	count: number;
}>;

export const toDomain = (data: TournamentRatingDTO): TournamentRating => {
	return data.result.users.map(item => ({
		place: item.place,
		login: item.login,
		photo: item.photo,
		balance: item.balance,
		prize: item.prize,
		colorCode: +item.color,
		current: item.current,
	}));
};

export const fetchTournamentRating: FetchTournamentRating = async (
	tournamentId,
) => {
	const result = await useApi()<TournamentRatingDTO>(
		'/Tournaments/getRatingTraders/',
		{
			payload: {
				id: tournamentId,
			},
		},
	);
	return result.mapRight(v => toDomain(v));
};
