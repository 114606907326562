<script setup lang="ts">
import { BaseCard, BaseCardTitle } from '@legacy-studio/library';
import VerificationCenterEmail from './Email.vue';
import VerificationCenterPersonal from './Personal.vue';
import VerificationCenterPersonality from './Personality.vue';
import { useUser } from '@/src/features/user';
import { UiVerificationBadge } from '@/src/shared/ui';

const user = useUser();
const status = computed(() => user.value?.verificationStatus);
</script>

<template>
	<BaseCard>
		<BaseCardTitle title="Центр верификации">
			<template #after>
				<UiVerificationBadge :status="status" />
			</template>
		</BaseCardTitle>

		<div class="flex flex-col">
			<VerificationCenterEmail class="pb-8 border-b border-gray-600" />

			<VerificationCenterPersonal class="py-8 border-b border-gray-600" />

			<VerificationCenterPersonality class="pt-8" />
		</div>
	</BaseCard>
</template>
