import type { SetUpdateBalance } from '~/src/features/tournaments/core/ports.input';
import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';
import { saveInvoices } from '~/src/features/invoices/infrastructure/store';

export const setUpdateBalance: SetUpdateBalance = async (tournamentId) => {
	const result = await tournamentsApi.updateTournametBalance(tournamentId);
	if (result.isRight()) {
		saveInvoices(result.value);
	}
};
