import { useApi } from '@/src/services/network';

export const useBlockAccount = () => {
	const api = useApi();

	return async () =>
		await api('/User/blockAccount/', {
			method: 'post',
		});
};
