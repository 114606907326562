<script setup lang="ts">
import { BaseIcon, type BaseIconName } from '@legacy-studio/library';

const props = defineProps<{
	status: VerificationStatus;
}>();

const iconName = computed<BaseIconName>(() => {
	switch (props.status) {
		case 'verified':
			return 'base--fill--check-circle';
		default:
			return 'base--fill--exclamation-circle';
	}
});

const verificationText = computed(() => {
	switch (props.status) {
		case 'verified':
			return 'Верифицирован';
		case 'unverified':
			return 'Не подтвержден';
		default:
			return 'В обработке';
	}
});

const badgeType = computed(() => {
	switch (props.status) {
		case 'verified':
			return 'success';
		case 'unverified':
			return 'error';
		default:
			return 'gray';
	}
});
</script>

<template>
	<BaseBadge :color="badgeType">
		<BaseIcon
			:name="iconName"
			class="text-[14px]"
		/>

		<span class="text-xs font-medium">
			{{ verificationText }}
		</span>
	</BaseBadge>
</template>
