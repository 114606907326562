import { useApi } from '@/src/services/network';

export const useDeleteAvatar = () => {
	const api = useApi();

	return async () =>
		await await api('/User/deletePhoto/', {
			method: 'post',
		});
};
