import { apiMethods } from '../../infrastructure/api';
import { saveIsBlockAccountModalOpen } from '../../infrastructure/store';

export const useBlockAccount = () => {
	const blockAccount = apiMethods.useBlockAccount();

	return async () => {
		const result = await blockAccount();

		if (result.isRight()) {
			saveIsBlockAccountModalOpen(false);
		}
		else {
			throw result.value;
		}
	};
};

export const closeBlockAccountModal = () => {
	saveIsBlockAccountModalOpen(false);
};

export const openBlockAccountModal = () => {
	saveIsBlockAccountModalOpen(true);
};
