import { saveSumData } from '../../infrastructure/store';
import { useSubscribeSumSelected } from '@/src/features/sum';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

export const useOnSumSelect = () => {
	useSubscribeSumSelected((sumData) => {
		pageDrawers.close(PAGE_DRAWERS.sum);
		saveSumData(sumData);
	});
};
