<script setup lang="ts">
import {
	BaseCard,
	BaseCardTitle,
	BaseSelect,
	useAsyncState,
} from '@legacy-studio/library';
import { useChangeLocale } from '../../core/application';
import { useUser } from '@/src/features/user';

const user = useUser();

const locale = computed(() => user.value?.language);

const i18n = useI18n();
const locales = computed(() => i18n.availableLocales);
const items = computed(() =>
	locales.value.map(locale => ({
		name: locale, // TODO
		code: locale,
	})),
);

const { isLoading, execute } = useAsyncState(useChangeLocale());
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Язык интерфейса" />

		<BaseSelect
			:model-value="locale"
			:items="items"
			size="regular"
			label="Выберите язык интерфейса"
			direction="top"
			@update:model-value="execute"
		>
			<template #value="{ currentText }">
				<div
					v-if="locale"
					class="flex items-center gap-2.5"
				>
					<img
						:src="`/img/lang/${locale}.png`"
						class="w-5"
					>

					<span>
						{{ currentText }}
					</span>
				</div>

				<span v-else>
					{{ currentText }}
				</span>
			</template>

			<template #item="{ item, modelValue, change }">
				<button
					class="flex items-center gap-2.5 px-4 py-2 text-left text-sm font-normal text-gray-400 hocus:bg-gray-600 hocus:text-white"
					:class="{
						'text-white': item.code === modelValue,
					}"
					@click="change(item.code)"
				>
					<!-- //TODO check change -->
					<img
						:src="`/img/lang/${item.code}.png`"
						class="w-5"
					>

					<span>
						{{ item.name }}
					</span>
				</button>
			</template>
		</BaseSelect>
	</BaseCard>
</template>
