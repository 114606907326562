<script setup lang="ts">
import { BaseButton, BaseCard, useAsyncState } from '@legacy-studio/library';
import { useDeleteAvatar, useUploadAvatar } from '../../core/application';
import { getBgColor, useUserRequired } from '@/src/features/user';
import { TariffsBadge } from '~/src/features/tariffs';

const user = useUserRequired();

const deleteAvatarCommand = useAsyncState(useDeleteAvatar());
const uploadAvatarCommand = useAsyncState(useUploadAvatar());

const pending = computed(
	() =>
		deleteAvatarCommand.isLoading.value || uploadAvatarCommand.isLoading.value,
);

const onUploadAvatar = (e: Event) => {
	uploadAvatarCommand.execute(
		(e.target as HTMLInputElement).files?.[0] as File,
	);
};

const onDeleteAvatar = deleteAvatarCommand.execute;
</script>

<template>
	<BaseCard :loading="pending">
		<div class="flex flex-col gap-4 md:flex-row">
			<div class="h-[112px] w-[112px] overflow-hidden rounded-lg">
				<img
					v-if="user.photo"
					class="h-full w-full object-cover"
					:src="user.photo"
					alt="avatar"
				>

				<div
					class="flex h-full w-full items-center justify-center text-3xl font-medium leading-none text-white"
					:class="getBgColor(user)"
				>
					{{ user.login[0] }}
				</div>
			</div>

			<div class="flex flex-col gap-4">
				<div class="flex flex-col gap-2">
					<div class="flex flex-col gap-3 md:flex-row md:items-center">
						<span class="text-2xl font-bold text-white">
							{{ user.login }}
						</span>

						<div class="flex gap-2">
							<ProfileIdLabel :value="user.id" />

							<TariffsBadge :type="user.accountType" />
						</div>
					</div>

					<span class="text-base text-gray-400">
						{{ user.email }}
					</span>
				</div>

				<div class="flex flex-col gap-4 md:flex-row">
					<label>
						<input
							type="file"
							class="sr-only"
							@input="onUploadAvatar"
						>

						<BaseButton
							color="alternative-dark"
							size="xs"
							:button-text="user.photo ? 'Изменить фото' : 'Загрузить фото'"
							outline
							class="w-fit"
						/>
						<!-- TODO -->
					</label>

					<BaseButton
						v-if="user.photo"
						color="alternative-dark"
						size="xs"
						button-text="Удалить фото"
						outline
						class="w-fit"
						@click="onDeleteAvatar"
					/>
				</div>
			</div>
		</div>
	</BaseCard>
</template>
