<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseTextField,
	BaseTextArea,
	getTextFieldBinds,
	BaseFetchWrapper,
} from '@legacy-studio/library';
import { type HelpForm } from '../types';
import { helpSchema } from '../core/validation';
import { UiDarkBBButton } from '~/src/shared/ui';

const { defineField, handleSubmit } = useForm<HelpForm>({
	validationSchema: helpSchema,
	initialValues: {
		name: '',
		email: '',
	},
});

const [name, nameProps] = defineField('name', getTextFieldBinds);
const [email, emailProps] = defineField('email', getTextFieldBinds);
const [message, messageProps] = defineField('message', getTextFieldBinds);

const onSubmit = handleSubmit(() => {});

const pending = ref(false);
</script>

<template>
	<BaseFetchWrapper :pending="pending">
		<form
			id="helpFrom"
			class="flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<BaseTextField
				v-model="name"
				v-bind="nameProps"
				label="Имя"
				size="regular"
				placeholder="Введите имя"
			/>

			<BaseTextField
				v-model="email"
				v-bind="emailProps"
				label="Ваш email"
				size="regular"
				placeholder="Введите email"
			/>

			<BaseTextArea
				v-model="message"
				v-bind="messageProps"
				label="Текст сообщения"
				size="regular"
			/>
		</form>

		<UiDarkBBButton
			size="sm"
			type="submit"
			button-text="Отправить"
			form="helpFrom"
		/>
	</BaseFetchWrapper>
</template>
