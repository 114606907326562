import { topUpApi } from '../../infrastructure/api';
import {
	readPaymentMethod,
	readPromocodeInfo,
	readSumData,
} from '../../infrastructure/store';
import { pageDrawers } from '~/src/features/page-drawers';

export const useSubmitTopUp = () => {
	const sendTopUp = topUpApi.useSendTopUp();

	return async () => {
		const paymentMethod = readPaymentMethod();
		const promocodeInfo = readPromocodeInfo();
		const sumData = readSumData();

		if (!paymentMethod || !sumData) {
			return;
		}

		const result = await sendTopUp({
			paymentMethod,
			sumData,
			promocode: promocodeInfo?.value || '',
		});

		if (result.isRight()) {
			window.open(result.value, '_blank');
			pageDrawers.close();
		}
	};
};
