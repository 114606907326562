<script setup lang="ts">
import { BaseFetchWrapper } from '@legacy-studio/library';
import { useGetCategories, useSelectPaymentMethod } from '../core/application';
import { usePaymentMethodsCategories } from '../infrastructure/store';
import { UiDarkBBButton } from '~/src/shared/ui';

defineProps<{
	text: string;
}>();

const selectPaymentMethod = useSelectPaymentMethod();

const { error, pending } = await useLazyAsyncData(useGetCategories());
const categories = usePaymentMethodsCategories();

const filterCategory = ref('');
const filteredCategories = computed(() => {
	if (!filterCategory.value) {
		return categories.value;
	}
	else {
		return (
			categories.value?.filter(
				category => category.code === filterCategory.value,
			) || []
		);
	}
});
</script>

<template>
	<BaseFetchWrapper
		:pending="pending"
		:error="error"
	>
		<div class="flex flex-col">
			<span class="text-sm font-normal text-gray-500">
				{{ text }}
			</span>

			<div class="flex gap-4 overflow-auto whitespace-nowrap py-6 px-4 -mx-4">
				<UiDarkBBButton
					:color123="!filterCategory ? 'dark-bb' : 'alternative-dark'"
					:outline="!!filterCategory"
					size="sm"
					button-text="Все"
					class="!rounded-full"
					@click="filterCategory = ''"
				/>

				<UiDarkBBButton
					v-for="button in categories"
					:key="button.code"
					:color123="
						filterCategory === button.code ? 'dark-bb' : 'alternative-dark'
					"
					size="sm"
					:outline="filterCategory !== button.code"
					:button-text="button.name"
					class="!rounded-full"
					@click="filterCategory = button.code"
				/>
			</div>

			<div class="flex flex-col gap-6">
				<div
					v-for="section in filteredCategories"
					:key="section.id"
					class="flex flex-col gap-6"
				>
					<span class="text-base font-semibold text-white">
						{{ section.name }}
					</span>

					<div class="flex flex-col gap-3">
						<button
							v-for="item in section.items"
							:key="item.id"
							type="button"
							class="bg-gray-600 rounded-lg p-3 flex items-center gap-3"
							@click="selectPaymentMethod(item)"
						>
							<img
								:src="item.image"
								class="w-[24px] h-[24px] object-contain"
							>

							<span class="text-base font-bold text-white">
								{{ item.name }}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</BaseFetchWrapper>
</template>
