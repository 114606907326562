<script setup lang="ts">
import { BaseFetchWrapper, formatAmount } from '@legacy-studio/library';
import { TariffsBadge } from '../../tariffs';
import { PAGE_DRAWERS, pageDrawers } from '../../page-drawers';
import ActiveButton from '~/src/features/tournaments/ui/ActiveButton.vue';
import { Timer } from '~/src/modules/timer';
import {
	geTournamentTimerInfo,
	selectTournamentItem,
	setParticipateTournament,
	type ITournamentItem,
} from '~/src/features/tournaments';

const props = defineProps<{
	item: ITournamentItem;
	status: string;
}>();

const tournamentItem = computed(() => props.item);
const timer = ref<Timer | null>();

const timerInfo = geTournamentTimerInfo(tournamentItem.value);

timer.value = new Timer({
	type: 'fullClock',
	direction: 'down',
	count: timerInfo.all,
	initial: timerInfo.past,
});
timer.value.start();

const timerData = computed(() => {
	if (!timer.value) {
		return null;
	}
	else {
		return {
			value: timer.value.value,
			percent: timer.value.percent,
		};
	}
});

const timerText = computed(() => {
	if (props.status === 'current') {
		return 'До окончания турнира';
	}
	else if (props.status === 'future') {
		return 'До начала турнира';
	}
	else return 'Турнир завершен';
});

const selectTournament = (item) => {
	selectTournamentItem(item);
	pageDrawers.open(PAGE_DRAWERS.tournament, { nested: true });
};

const setParticipateInTournament = () => {
	setParticipateTournament(tournamentItem.value.id);
};
</script>

<template>
	<BaseFetchWrapper>
		<div
			class="flex gap-4 h-full w-full max-w-[448px] flex-col rounded-lg border border-gray-600 bg-gray-900 p-5 shadow-2xl"
			@click="selectTournament(tournamentItem)"
		>
			<div class="relative">
				<img
					:src="tournamentItem.picture"
					class="object-contain rounded-lg"
				>
				<div
					class="flex flex-col backdrop-blur-[32px] bg-opacity-30 text-xs font-normal text-white uppercase absolute top-0 left-0 px-3.5 py-1.5"
				>
					<span>Призовой фонд</span>
					<span class="text-sm font-bold">{{
						formatAmount(tournamentItem.prize_fund)
					}}</span>
				</div>
			</div>

			<TariffsBadge :type="tournamentItem.tariff.code" />

			<div class="flex flex-col gap-1">
				<span class="text-start text-lg font-bold text-white">
					{{ timerData.value }}
				</span>
				<div class="h-[10px] w-full bg-gray-700 dark:bg-neutral-600">
					<div
						class="h-[10px] bg-primary-300"
						:style="{ width: timerData.percent + '%' }"
					/>
				</div>
				<span class="text-xs font-normal text-gray-400">
					{{ timerText }}
				</span>
			</div>

			<p class="text-start text-lg font-bold text-white">
				{{ tournamentItem.name }}
			</p>

			<ActiveButton
				:item="tournamentItem"
				@participate.once="setParticipateInTournament"
			/>
		</div>
	</BaseFetchWrapper>
</template>
