import type { SendWithdrawal } from '../../core/ports.output';
import { useApi } from '@/src/services/network';

export const sendWithdrawal: SendWithdrawal = async (data) => {
	return await useApi()('/Transactions/withdraw/', {
		method: 'post',
		payload: {
			paysystem: data.withdrawalMethod.id,
			sum: data.sum,
			account: data.address,
		},
	});
};
