<script setup lang="ts">
import { BaseTextField } from '@legacy-studio/library';
import { useSelectSum } from '../core/application';
import { type TariffItem, usePaymentTariffs } from '@/src/features/tariffs';
import { UiDarkBBButton } from '~/src/shared/ui';

const tariffs = usePaymentTariffs();

const sum = ref('');
const tariff = ref<TariffItem | null>(null);

const sumModel = computed({
	get() {
		return String(tariff.value?.sum || sum.value);
	},

	set(value) {
		if (tariff.value) {
			tariff.value = null;
		}
		sum.value = String(value);
	},
});

const selectSum = useSelectSum();

const apply = () => {
	selectSum({
		tariff: tariff.value,
		sum: tariff.value?.sum || +sum.value,
	});
};
</script>

<template>
	<div class="flex flex-col gap-8">
		<div class="flex flex-col gap-4">
			<BaseTextField
				v-model="sumModel"
				label="Введите сумму платежа"
				placeholder="0"
				size="regular"
				right="USD"
			/>

			<span class="text-sm font-normal text-gray-500">
				Или выберите готовый вариант:
			</span>

			<div class="flex flex-col gap-3">
				<button
					v-for="item in tariffs"
					:key="item.id"
					type="button"
					class="bg-gray-600 rounded-lg px-3 py-2 flex items-center gap-3 justify-between"
					@click="tariff = item"
				>
					<span class="text-base font-bold text-white">
						{{ `$ ${item.sum}` }}
					</span>

					<div class="flex gap-1 items-center px-4 py-0.5">
						<AccountsIcon
							:type="item.code"
							class="text-[16px]"
						/>

						<AccountsTitle
							:type="item.code"
							class="text-white text-sm font-medium"
						/>
					</div>
				</button>
			</div>
		</div>

		<UiDarkBBButton
			v-if="sum || tariff"
			size="sm"
			button-text="Подтвердить"
			@click="apply"
		/>
	</div>
</template>
