<script setup lang="ts">
import { getBonusesTimerInfo } from '../core/domain';
import type { BonusesItem } from '../types';
import { useSelectBonusesItem } from '../core/applictation';
import { UiDarkBBButton } from '~/src/shared/ui';
import { Timer } from '~/src/modules/timer';

const props = defineProps<{
	item: BonusesItem;
}>();

const timer = ref<Timer | null>();
const timerInfo = getBonusesTimerInfo(props.item);

timer.value = new Timer({
	type: 'fullClock',
	direction: 'down',
	count: timerInfo.all,
	initial: timerInfo.past,
});
timer.value.start();

const timerData = computed(() => {
	if (!timer.value) {
		return null;
	}
	else {
		return {
			value: timer.value.value,
			percent: timer.value.percent,
		};
	}
});

const selectBonusesItem = useSelectBonusesItem();
</script>

<template>
	<div
		class="flex gap-4 h-full w-full max-w-[448px] flex-col rounded-lg border border-gray-600 bg-gray-900 p-5 shadow-2xl"
		@click="selectBonusesItem(item)"
	>
		<div class="relative">
			<img
				:src="item.image"
				class="object-contain rounded-lg"
			>
		</div>

		<div
			v-if="timerData"
			class="flex flex-col gap-1"
		>
			<span class="text-start text-lg font-bold text-white">
				{{ timerData.value }}
			</span>

			<div class="h-[10px] w-full bg-gray-700 dark:bg-neutral-600">
				<div
					class="h-[10px] bg-primary-300"
					:style="{ width: timerData.percent + '%' }"
				/>
			</div>

			<span class="text-xs font-normal text-gray-400">
				До окончания акции
			</span>
		</div>

		<p class="text-start text-lg font-bold text-white">
			{{ item.title }}
		</p>

		<UiDarkBBButton
			:disabled="item.active === 0"
			size="sm"
			button-text="Подробнее"
			class="!rounded-full w-100"
		/>
	</div>
</template>
