<script setup lang="ts">
import { BaseDrawer } from '@legacy-studio/library';
import { closeSidebar, useIsTraderLayout } from '../../core/application';
import { useIsSidebarOpen } from '../../infrastructure/store';

const isTraderLayout = useIsTraderLayout();
const isSidebarOpen = useIsSidebarOpen();
</script>

<template>
	<template v-if="isTraderLayout">
		<div
			v-if="$viewport.match('sm') ? isSidebarOpen : true"
			class="fixed right-0 md:right-auto bottom-0 left-0 top-16 z-[70] overflow-y-auto border-r border-gray-600 bg-gray-800"
		>
			<slot />
		</div>
	</template>

	<template v-else>
		<div
			v-if="$viewport.match('xl')"
			class="fixed bottom-0 left-0 top-16 z-10 overflow-y-auto border-r border-gray-600 bg-gray-800"
		>
			<slot />
		</div>

		<BaseDrawer
			v-else
			title="MENU"
			:model-value="isSidebarOpen"
			class="px-3 pb-[54px] pt-4"
			@update:model-value="closeSidebar"
		>
			<slot />
		</BaseDrawer>
	</template>
</template>
