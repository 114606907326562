import { apiMethods } from '../../infrastructure/api';
import { useGetUser } from '@/src/features/user';

export const useUploadAvatar = () => {
	const uploadAvatar = apiMethods.useUploadAvatar();
	const getUser = useGetUser();

	return async (avatar: File) => {
		const result = await uploadAvatar(avatar);

		if (result.isRight()) {
			await getUser();
		}
		else {
			throw result.value;
		}
	};
};
