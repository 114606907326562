import { useApi } from '@/src/services/network';

export const useChangeLocale = () => {
	const api = useApi();

	return async (language: string) =>
		await await api('/User/update/', {
			method: 'post',
			body: {
				language,
			},
		});
};
