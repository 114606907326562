<script setup lang="ts">
import {
	BaseTable,
	BaseTableBody,
	BaseTableBodyCellText,
	BaseTableBodyCellDate,
	BaseTableBodyCellStatus,
	BaseTableBodyRow,
	BaseTableHead,
	BaseTableHeadRow,
	BaseTableHeadCell,
	useTable,
} from '@legacy-studio/library';
import { useTransactionsList } from '../infrastructure/store';
import { COL_NAMES } from '../constants';

const items = useTransactionsList();

const { titleList } = useTable({
	hiddenCols: computed(() => []),
	items: computed(() => []),
	colNames: COL_NAMES,
});
</script>

<template>
	<BaseTable class="rounded-lg max-w-[1260px] mx-auto w-full">
		<BaseTableHead class="text-left">
			<BaseTableHeadRow>
				<BaseTableHeadCell
					v-for="title in titleList"
					:key="title"
					:title="title"
				/>
			</BaseTableHeadRow>
		</BaseTableHead>

		<BaseTableBody>
			<BaseTableBodyRow
				v-for="item in items"
				:key="item.number"
			>
				<BaseTableBodyCellDate
					:date-string="item.date"
					format="DD.MM.YYYY HH:mm"
				/>
				<BaseTableBodyCellText :text="item.number" />
				<BaseTableBodyCellText :text="item.type" />
				<BaseTableBodyCellText :text="item.asset" />
				<BaseTableBodyCellStatus
					:variant="item.status.variant"
					:text="item.status.text"
				/>
				<BaseTableBodyCellText :text="item.sum" />
			</BaseTableBodyRow>
		</BaseTableBody>
	</BaseTable>
</template>
