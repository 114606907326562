import { apiMethods } from '../../infrastructure/api';
import type { EditEmailForm } from '../../types';
import { saveIsEmailEdit } from '../../infrastructure/store';
import { useGetUser } from '@/src/features/user';

export const useChangeEmail = () => {
	const changeEmail = apiMethods.useChangeEmail();
	const getUser = useGetUser();

	return async (payload: EditEmailForm) => {
		const result = await changeEmail(payload);

		if (result.isRight()) {
			await getUser();
			saveIsEmailEdit(false);
		}
		else {
			throw result.value;
		}
	};
};

export const setEmailEdit = () => {
	saveIsEmailEdit(true);
};

export const setEmailNotEdit = () => {
	saveIsEmailEdit(false);
};
