import { apiMethods } from '../../infrastructure/api';
import { readInvoices, saveInvoices } from '../../infrastructure/store';

export const useRefreshDemoInvoice = () => {
	const refreshDemoInvoice = apiMethods.useRefreshDemoInvoice();

	return async () => {
		const result = await refreshDemoInvoice();

		if (result.isRight()) {
			const updatedDemoInvoice = result.value;
			const invoices = readInvoices();
			const invoicesWithoutDemo = invoices.filter(
				item => item.id !== updatedDemoInvoice.id,
			);

			saveInvoices([...invoicesWithoutDemo, updatedDemoInvoice]);
		}
	};
};
