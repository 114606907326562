<script setup lang="ts">
import {
	BaseFetchWrapper,
	BaseSelectButton,
	formatAmount,
	useAsyncState,
} from '@legacy-studio/library';
import {
	usePaymentMethod,
	usePromocodeInfo,
	useSumData,
} from '../infrastructure/store';
import { useGetPaymentMethodData } from '../../payment-method';
import {
	useSubmitTopUp,
	useOnSumSelect,
	useOnPaymentMethodSelect,
} from '../core/application';
import { TariffsIcon } from '../../tariffs';
import { PAGE_DRAWERS, pageDrawers } from '../../page-drawers';
import { getProcessedPromocodeData } from '../core/domain';
import TopUpPromocode from './Promocode.vue';
import { UiDarkBBButton } from '~/src/shared/ui';

useOnPaymentMethodSelect();
useOnSumSelect();

const sumData = useSumData();
const promocodeInfo = usePromocodeInfo();
const processedPromocodeData = computed(() => {
	if (!sumData.value?.sum) {
		return null;
	}
	else {
		return getProcessedPromocodeData(sumData.value.sum, promocodeInfo.value);
	}
});

const getPaymentMethodData = useGetPaymentMethodData();

const paymentMethod = usePaymentMethod();

const paymentItemData = computed(() => {
	if (paymentMethod.value) {
		return getPaymentMethodData(paymentMethod.value.id);
	}
	else {
		return null;
	}
});

const { isLoading, error, execute } = useAsyncState(useSubmitTopUp());
</script>

<template>
	<BaseFetchWrapper
		:pending="isLoading"
		:error="error"
	>
		<div class="flex flex-col gap-8">
			<div class="flex flex-col gap-4">
				<BaseSelectButton
					label="Платежный метод"
					placeholder="Выберите платежный метод"
					size="regular"
					:value="paymentItemData?.category.name"
					:right="paymentItemData?.category.name"
					@click="
						pageDrawers.open(PAGE_DRAWERS.paymentMethod, { nested: true })
					"
				/>

				<BaseSelectButton
					v-if="paymentItemData"
					label="Сумма платежа"
					placeholder="0"
					size="regular"
					right="USD"
					:value="sumData?.sum ? String(sumData?.sum) : ''"
					@click="pageDrawers.open(PAGE_DRAWERS.sum, { nested: true })"
				>
					<template
						v-if="sumData?.tariff"
						#right
					>
						<div
							class="flex gap-2.5 leading-tight text-sm font-medium text-gray-500"
						>
							<TariffsIcon
								:type="sumData.tariff.code"
								class="text-[14px]"
							/>

							<span> USD </span>
						</div>
					</template>

					<template
						v-if="sumData?.tariff"
						#message
					>
						<span
							class="leading-tight text-sm font-normal text-gray-500 text-left"
						>
							Доступны преимущества счета
							<span class="text-white uppercase">
								{{ sumData.tariff.name }}
							</span>
						</span>
					</template>
				</BaseSelectButton>
			</div>

			<template v-if="sumData">
				<div class="h-[1px] bg-gray-600" />

				<TopUpPromocode />

				<div
					v-if="processedPromocodeData?.type === 'withPercent'"
					class="h-[1px] bg-gray-600"
				/>

				<div
					v-if="processedPromocodeData?.type === 'withPercent'"
					class="flex flex-col"
				>
					<span class="text-sm font-normal text-gray-400">
						Вместе с депозитом вы получите бонус по промокоду в размере
						<span class="text-white">
							{{ `${processedPromocodeData.percent}%` }}
						</span>
					</span>
					<span class="text-sm font-medium text-green-400">
						{{ `+ ${formatAmount(processedPromocodeData.bonusSum)}` }}
					</span>
				</div>

				<div class="h-[1px] bg-gray-600" />

				<div
					v-if="processedPromocodeData"
					class="flex"
				>
					<div
						v-if="processedPromocodeData.type === 'withPercent'"
						class="flex flex-col gap-1 flex-1"
					>
						<span class="text-sm font-normal text-gray-400"> Вы получите</span>

						<span class="text-2xl font-bold text-green-400">
							{{ `$ ${processedPromocodeData.recieveSum}` }}
						</span>
					</div>

					<div class="flex flex-col gap-1 flex-1">
						<span class="text-sm font-normal text-gray-400">
							Сумма к оплате
						</span>

						<span class="text-2xl font-bold text-white">
							{{ `$ ${processedPromocodeData.paymentSum}` }}
						</span>
					</div>
				</div>

				<UiDarkBBButton
					size="sm"
					button-text="Продолжить"
					@click="execute"
				/>
			</template>
		</div>
	</BaseFetchWrapper>
</template>
