import { useUploadAvatar } from './uploadAvatar';
import { useDeleteAvatar } from './deleteAvatar';
import { useUpdateLogin } from './updateLogin';
import { useChangePassword } from './changePassword';
import { useBlockAccount } from './blockAccount';
import { useChangeLocale } from './changeLocale';
import { useChangeEmail } from './changeEmail';

export const apiMethods = {
	useUploadAvatar,
	useDeleteAvatar,
	useUpdateLogin,
	useChangePassword,
	useBlockAccount,
	useChangeLocale,
	useChangeEmail,
};
