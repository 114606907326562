<script setup lang="ts">
import { useActiveDeals } from '../infrastructure/store';
import ActiveDealsList from './ActiveList.vue';
import DealsSectionTemplate from './SectionTemplate.vue';

const activeDeals = useActiveDeals();
</script>

<template>
	<DealsSectionTemplate
		v-if="activeDeals.length"
		title="Активные сделки"
	>
		<div class="flex flex-col gap-4">
			<ActiveDealsList />
		</div>
	</DealsSectionTemplate>
</template>
