import { apiMethods } from '../../infrastructure/api';
import { saveIsChangePasswordEdit } from '../../infrastructure/store';
import type { ChangePasswordForm } from '../../types';
import { useGetUser } from '@/src/features/user';

export const useChangePassword = () => {
	const changePassword = apiMethods.useChangePassword();
	const getUser = useGetUser();

	return async (payload: ChangePasswordForm) => {
		const result = await changePassword(payload);

		if (result.isRight()) {
			await getUser();
			saveIsChangePasswordEdit(false);
		}
		else {
			throw result.value;
		}
	};
};

export const openChangePasswordModal = () => {
	saveIsChangePasswordEdit(true);
};

export const closeChangePasswordModal = () => {
	saveIsChangePasswordEdit(false);
};
