<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

const sections = computed(() => [
	{
		code: PAGE_DRAWERS.helpForm,
		title: 'Форма для связи',
	},
	{
		code: PAGE_DRAWERS.helpBot,
		title: 'Поддержка в Telegram',
	},
]);
</script>

<template>
	<BaseFetchWrapper>
		<div class="flex flex-col gap-6 pt-4">
			<button
				v-for="section in sections"
				:key="section.code"
				type="button"
				class="text-gray-400 hover:text-white leading-tight text-sm font-normal flex justify-between items-center pb-3 border-b border-gray-600"
				@click="pageDrawers.open(section.code, { nested: true })"
			>
				<span>
					{{ section.title }}
				</span>

				<BaseIcon
					name="base--fill--chevron-right"
					class="text-[18px]"
				/>
			</button>
		</div>
	</BaseFetchWrapper>
</template>
