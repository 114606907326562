<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseCard,
	BaseCardTitle,
	BaseTextField,
	getTextFieldBinds,
	useAsyncState,
} from '@legacy-studio/library';
import {
	setEmailEdit,
	setEmailNotEdit,
	useChangeEmail,
} from '../../core/application';
import { editEmailSchema } from '../../core/validation';
import { useIsEmailEdit } from '../../infrastructure/store';
import type { EditEmailForm } from '../../types';
import EditForm from './Form.vue';
import { UiVerificationLabel } from '@/src/shared/ui';
import { useUser } from '@/src/features/user';

const isEmailEdit = useIsEmailEdit();

const user = useUser();
const currentEmail = computed(() => user.value?.email || '');
const status = computed(() => user.value?.emailVerified || false);
const verificationEmailText = computed(() => {
	let verificationText = 'Не подтверждена';

	if (status.value) {
		verificationText = 'Подтверждена';
	}

	return `${email.value}: ${verificationText} `;
});

const { defineField, handleSubmit } = useForm<EditEmailForm>({
	validationSchema: editEmailSchema,
	initialValues: {
		email: currentEmail.value,
	},
});

const [email, emailProps] = defineField('email', getTextFieldBinds);

const { isLoading, execute } = useAsyncState(useChangeEmail());

const onSubmit = handleSubmit(execute);
</script>

<template>
	<BaseCard :loading="isLoading">
		<BaseCardTitle title="Электронная почта" />

		<div class="flex items-center justify-between gap-[30px]">
			<UiVerificationLabel
				:status="status"
				:title="verificationEmailText"
				subtitle="Вы можете сменить электронную почту, к которой привязан ваш аккаунт"
			/>
			<!-- TOOD: status -->

			<BaseButton
				v-if="!isEmailEdit"
				color="alternative-dark"
				size="xs"
				button-text="Изменить"
				outline
				class="w-full md:w-auto"
				@click="setEmailEdit"
			/>
		</div>

		<EditForm
			v-if="isEmailEdit"
			class="mt-4"
			@submit="onSubmit"
			@cancel="setEmailNotEdit"
		>
			<BaseTextField
				v-model="email"
				v-bind="emailProps"
				size="regular"
				dark
				placeholder="Введите новый e-mail"
				label="Новый e-mail"
			/>
		</EditForm>
	</BaseCard>
</template>
