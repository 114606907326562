<script setup lang="ts">
import { useForm } from 'vee-validate';
import {
	BaseTextField,
	getTextFieldBinds,
	useAsyncState,
} from '@legacy-studio/library';
import { useWithdrawalMethod } from '../infrastructure/store';
import { useGetPaymentMethodData } from '../../payment-method';
import { withdrawalSchema } from '../core/validation';
import type { WithdrawalForm } from '../core/types';
import {
	submitWithdrawal,
	useOnWithdrawalMethodSelect,
} from '../core/application';
import { PAGE_DRAWERS, pageDrawers } from '../../page-drawers';
import { UiDarkBBButton } from '~/src/shared/ui';

useOnWithdrawalMethodSelect();

const getPaymentMethodData = useGetPaymentMethodData();
const paymentItemData = computed(() => {
	const withdrawalMethod = useWithdrawalMethod();
	if (withdrawalMethod) {
		return getPaymentMethodData(withdrawalMethod.id);
	}
	else {
		return null;
	}
});

const { defineComponentBinds, handleSubmit, setFieldValue }
  = useForm<WithdrawalForm>({
  	validationSchema: withdrawalSchema,
  	initialValues: {
  		type: paymentItemData.value?.category.code,
  	},
  });

watch(
	() => paymentItemData.value?.category.code,
	(val) => {
		if (val) {
			setFieldValue('type', val);
		}
	},
);

const address = defineComponentBinds('address', getTextFieldBinds);
const sum = defineComponentBinds('sum', getTextFieldBinds);

const { execute, isLoading, error } = useAsyncState(submitWithdrawal);

const onSubmit = handleSubmit(async (values) => {
	await execute(values);
});
</script>

<template>
	<BaseFetchWrapper
		:pending="isLoading"
		:error="error"
	>
		<form
			id="withdrawalFrom"
			class="flex flex-col gap-4"
			@submit.prevent="onSubmit"
		>
			<BaseSelectButton
				label="Метод"
				placeholder="Выберите метод вывода"
				size="regular"
				:value="paymentItemData?.category.name"
				:right="paymentItemData?.name"
				@click="
					pageDrawers.open(PAGE_DRAWERS.withdrawalMethod, { nested: true })
				"
			/>

			<template v-if="paymentItemData">
				<BaseTextField
					v-if="paymentItemData.category.code === 'crypto'"
					v-bind="address"
					label="Адрес"
					size="regular"
					placeholder="Удерживайте, чтобы вставить"
					message="Вставляйте только адрес ........."
				/>

				<BaseTextField
					v-else-if="paymentItemData.category.code === 'electroWallet'"
					v-bind="address"
					label="Адрес"
					size="regular"
					placeholder="Номер счета или телефона"
				/>

				<BaseTextField
					v-else-if="paymentItemData.category.code === 'bankCard'"
					v-bind="address"
					label="Номер карты"
					size="regular"
					placeholder="0000-0000-0000-0000"
					maska="####-####-####-####"
				/>

				<BaseTextField
					v-bind="sum"
					label="Сумма"
					placeholder="0.00"
					size="regular"
					right="USD"
				>
					<template #message>
						<span>
							Доступно для вывода <span class="text-white">?????</span>
						</span>
					</template>
				</BaseTextField>
			</template>
		</form>

		<UiDarkBBButton
			v-if="paymentItemData"
			size="sm"
			type="submit"
			button-text="Вывод средств"
			form="withdrawalFrom"
		/>
	</BaseFetchWrapper>
</template>
