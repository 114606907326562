import type { SetParticipateTournament } from '../../core/ports.output';
import { useApi } from '@/src/services/network';
import type {
	InvoicesDTO } from '~/src/features/invoices/infrastructure/api/getInvoices';
import {
	toDomain,
} from '~/src/features/invoices/infrastructure/api/getInvoices';

export const setParticipateInTournament: SetParticipateTournament = async (
	tournamentId,
) => {
	const result = await useApi()<InvoicesDTO>(
		'/Tournaments/participateInTraderTournament/',
		{
			payload: {
				id: tournamentId,
			},
		},
	);
	return result.mapRight(v => toDomain(v));
};
