import dayjs from 'dayjs';
import type { DealsSections, DealsGroups } from '../types';

export const groupDealsSections = (sections: DealsSections): DealsGroups => {
	const map: Record<DateString, DealsSections> = {};

	const formatTitle = (date?: DateString) => dayjs(date).format('DD MMM YYYY');

	sections.forEach((section) => {
		const dateString = formatTitle(section.items[0].expirationDate);

		if (dateString in map) {
			map[dateString].push(section);
		}
		else {
			map[dateString] = [section];
		}
	});

	const current = formatTitle();

	return Object.entries(map).map(([title, sections]) => ({
		title: current === title ? '' : title,
		sections,
	}));
};
