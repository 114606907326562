import { saveWithdrawalMethod } from '../../infrastructure/store';
import { useSubscribePaymentMethodSelected } from '@/src/features/payment-method';
import { PAGE_DRAWERS, pageDrawers } from '~/src/features/page-drawers';

export const useOnWithdrawalMethodSelect = () => {
	useSubscribePaymentMethodSelected((method) => {
		pageDrawers.close(PAGE_DRAWERS.withdrawalMethod);
		saveWithdrawalMethod(method);
	});
};
