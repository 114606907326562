import type { GetTournamentRating } from '~/src/features/tournaments/core/ports.input';
import { tournamentsApi } from '~/src/features/tournaments/infrastructure/api';
import { saveSelectedTournamentRating } from '~/src/features/tournaments/infrastructure/store';

export const getTournamentRating: GetTournamentRating = async (
	tournamentId,
) => {
	const result = await tournamentsApi.fetchTournamentRating(tournamentId);
	if (result.isRight()) {
		saveSelectedTournamentRating(result.value);
	}
};
